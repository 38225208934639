import Vue from 'vue'
import App from './App.vue'
import router from './router'
import mandMobile from 'mand-mobile'
import 'mand-mobile/lib/mand-mobile.css'

Vue.use(mandMobile)

Vue.config.productionTip = false

// 设置统一的headers
Vue.$headers = {
  "Content-Type": "multipart/form-data"
}

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
